const getValidCache = (cachedItemStr, cacheValidity) => {
  if (!cachedItemStr) {
    return null;
  }

  const parsedCachedItem = JSON.parse(cachedItemStr);

  const isNextDay = function (d) {
    var testDate = new Date(d);
    var nowDate = new Date();
    return (
      testDate.getMonth() + testDate.getDate() !==
      nowDate.getMonth() + nowDate.getDate()
    );
  };

  if (cacheValidity === "nextday") {
    // cache expires "next day" (after midnight) relative to the cache timestamp
    if (isNextDay(parsedCachedItem.timestamp)) {
      return null;
    } else {
      return parsedCachedItem.value;
    }
  }
};

export default getValidCache;
