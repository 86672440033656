import app from "firebase/app";
import "firebase/database";

// const config = {
//     apiKey: 'AIzaSyA5qhVdSzT70aYqUE5TV83TWTMA9Ff0gts',
//     authDomain: 'fancypants-20bc6.firebaseapp.com',
//     databaseURL: 'https://fancypants-20bc6.firebaseio.com',
//     projectId: 'fancypants-20bc6',
//     storageBucket: '',
//     messagingSenderId: '397741107013',
//   };

const config = {
  apiKey: "AIzaSyCw_PrvryfCWC22KUFPG9Dyq0Sk3DPKakA",
  authDomain: "discgolfvalley.firebaseapp.com",
  databaseURL: "https://discgolfvalley.firebaseio.com",
  projectId: "discgolfvalley",
  storageBucket: "",
  messagingSenderId: "480706417920",
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.db = app.database();
  }

  // API
  users = () => this.db.ref().child("rat").orderByChild("rt").limitToLast(25);
}

export default Firebase;
