import React from "react";
import styles from "./header.module.scss";

const Header = (props) => {
  return (
    <header className={styles.HeaderContainer}>
      <div className={styles.InnerContainer}>
        <a href="https://www.latitude64.se/">
          <img
            className={styles.HeaderLogo}
            alt="Latitude 64"
            src={require("../../../assets/images/latitude64-logo-280x100.png")}
          ></img>
        </a>
      </div>
    </header>
  );
};

export default Header;
