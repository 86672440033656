import React from "react";
import styles from "./rating.module.scss";

const rating = (props) => {
  return (
    <div className={`${styles.RatingContainer} row`}>
      <div className="col-md-1">
        <span className="d-md-none mr-2">
          <strong>#</strong>
        </span>
        <span>{props.position + 1}</span>
      </div>
      <div className="col-md">
        <span className="d-md-none mr-2">
          <strong>Name:</strong>
        </span>
        <span>{props.name}</span>
      </div>
      <div className="col-md">
        <span className="d-md-none mr-2">
          <strong>Rating:</strong>
        </span>
        <span>{props.rating}</span>
      </div>
      <div className="col-md">
        <span className="d-md-none mr-2">
          <strong>Change:</strong>
        </span>
        <span>{props.change}</span>
      </div>
    </div>
  );
};

export default rating;
