import React from "react";
import Rating from "../Rating/rating";
import styles from "./ratingList.module.scss";

const ratingList = (props) => {
  return (
    <div className={`${styles.RatingList}`}>
      <div>
        <h1 className="pb-3">RATINGS</h1>
      </div>

      <section>
        <header className={`${styles.RatingHeaderRow} row d-none d-md-flex`}>
          <div className="col-md-1">
            <span>#</span>
          </div>
          <div className="col-md">
            <span>Name</span>
          </div>
          <div className="col-md">
            <span>Rating</span>
          </div>
          <div className="col-md">
            <span>Change</span>
          </div>
        </header>

        {props.ratings.map((ratingItem, index) => {
          return (
            <Rating
              position={index}
              key={ratingItem.uid}
              change={ratingItem.df}
              name={ratingItem.nm}
              rating={ratingItem.rt}
            />
          );
        })}
      </section>
    </div>
  );
};

export default ratingList;
