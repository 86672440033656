import React from "react";
import logo from "./logo.svg";
import styles from "./App.module.scss";
import Layout from "./components/Layout/layout";

function App() {
  return (
    <div className={styles.App}>
      <Layout></Layout>
    </div>
  );
}

export default App;
