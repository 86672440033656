import React from "react";
import styles from "./badges.module.scss";

const Badges = (props) => {
  return (
    <div className="d-flex justify-content-center mt-2">
      <div className={styles.BadgeContainer}>
        <a href="https://apps.apple.com/us/app/disc-golf-valley/id1454709572">
          <img
            className={styles.BadgeIOS}
            alt="Get it on App Store"
            src={require("../../../assets/images/App_Store_Badge_US-UK_RGB_blk_092917.svg")}
          />
        </a>
      </div>
      <div className={styles.BadgeContainer}>
        <a href="https://play.google.com/store/apps/details?id=com.Per.DiscGolf&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            className={styles.Badge}
            alt="Get it on Google Play"
            // src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            src={require("../../../assets/images/google-play-badge.png")}
          />
        </a>
      </div>
    </div>
  );
};

export default Badges;
