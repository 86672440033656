import React from "react";

import Header from "../UI/header/header";
import Banner from "../UI/Banner/banner";
import Badges from "../UI/Badges/badges";
import styles from "./layout.module.scss";
import RatingListContainer from "../../containers/RatingListContainer/ratingListContainer";

const layout = (props) => (
  <div className={styles.Container}>
    <Header></Header>
    <Banner></Banner>
    <Badges></Badges>
    <div className="pt-4 pb-4">
      {props.children}
      <RatingListContainer />
    </div>
  </div>
);

export default layout;
