import React, { useState, useEffect } from "react";

import RatingList from "../../components/RatingList/ratingList";
import { withFirebase } from "../../components/Firebase";

import getValidCache from "../../shared/cache";

function RatingListContainer(props) {
  const [users, setUsers] = useState([]);
  const [loading, setIsLoading] = useState(true);

  const usersListCache = localStorage.getItem("usersList") || "";

  useEffect(() => {
    if (!usersListCache || !getValidCache(usersListCache, "nextday")) {
      props.firebase.users().on("value", (snapshot) => {
        const usersObject = snapshot.val();

        const usersList = Object.keys(usersObject)
          .map((key) => ({
            ...usersObject[key],
            uid: key,
          }))
          .sort((a, b) => b.rt - a.rt);

        setUsers((prevUsersState) => usersList);
        setIsLoading((prevLoadingState) => false);

        const cacheItem = {
          value: usersList,
          timestamp: new Date().getTime(),
        };
        localStorage.setItem("usersList", JSON.stringify(cacheItem));
      });
    } else {
      if (usersListCache) {
        const usersList = JSON.parse(usersListCache);
        console.log(usersList.value);
        setUsers((prevUsersState) => (usersList.value ? usersList.value : []));
        setIsLoading((prevLoadingState) => false);
      }
    }
  }, []);

  return <RatingList ratings={users} />;
}

export default withFirebase(RatingListContainer);
