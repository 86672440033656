import React from "react";
import styles from "./banner.module.scss";

const Banner = (props) => {
  return (
    <div>
      <img
        className={styles.Banner}
        alt="Discgolf Valley"
        src={require("../../../assets/images/FeatureGraphicWide.png")}
      ></img>
    </div>
  );
};

export default Banner;
